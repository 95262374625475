<template>
  <nav
    class="bg-white border-b border-t px-6 py-2 flex flex-row justify-between border-gray-200d-header"
  >
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: "PageHeader",
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variables.scss";
.fixed-page-header {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}
</style>
