<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Rapports des achats par fournisseur
      </div>
    </PageHeader>
    <div class="p-6">
      <div class="flex justify-end items-center">
        <!--  <el-select
        v-model="listQuery.type"
        class="mr-2"
        @change="HANDLE_TYPE_RAPPORT"
      >
        <el-option
          v-for="item in typeData"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
        <el-select
          v-model="typeSearch"
          class="mr-2"
          placeholder="Select"
          @change="HANDLE_PLAGE"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="listQuery.start"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd MMM yyyy"
          placeholder="Date debut"
        >
        </el-date-picker>
        <span class="mx-2">Au</span>
        <el-date-picker
          class="mr-2"
          v-model="listQuery.end"
          type="date"
          value-format="yyyy-MM-dd"
          format="dd MMM yyyy"
          placeholder="Date debut"
        ></el-date-picker>

        <el-button
          @click="INIT_DATA"
          class="ml-2"
          icon="el-icon-search"
          :disabled="btnSeachValid"
        >
          Rechercher
        </el-button>
        <el-dropdown @command="HANDLE_EXPORT">
          <el-button class="ml-2" :loading="exportLoading">
            Exporter
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
<!--            <el-dropdown-item command="PDF">En PDF </el-dropdown-item>-->
            <el-dropdown-item command="EXCEL">En EXCEL</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-loading="listLoading">
        <!--       <div class="flex-1 bg-white border p-3">
          <div>
            <ChartSale
              :chartCategories="chartCategories"
              :chartData="chartSeries"
            />
          </div>
        </div>
      </div> -->
        <div class="p-4 my-5 bg-white shadow">
          <el-table :data="items" v-loading="listLoading" style="width: 100%">
            <el-table-column label="N° Client" width="140">
              <template slot-scope="{ row }">
                <span class="text-blue">{{ row.reference }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Client" min-width="200">
              <template slot-scope="{ row }">
                <span>{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Nbre de Facture" align="center" width="150">
              <template slot-scope="{ row }">
                <span class="">{{ row.total_item }} </span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="TOTAL HT" width="170">
              <template slot-scope="{ row }">
                <span class="">{{ row.total_ht | moneyFilter }} </span>
              </template>
            </el-table-column>

            <el-table-column label="TOTAL TTC" align="right" width="160">
              <template slot-scope="{ row }">
                <span>{{ row.total_ttc | moneyFilter }} </span>
              </template>
            </el-table-column>

          </el-table>
          <div class="flex justify-end">
            <pagination
              v-show="listQuery.total > 0"
              :total="listQuery.total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.size"
              @pagination="GET_FETCH_RAPPORT()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { toThousandFilter } from "@/Filters";
//import ChartSale from "./chart.vue";

import {
  parseDate,
  getDay,
  getStartOfWeek,
  getEndOfWeek,
  getStartOfMonth,
  getEndOfMonth,
  getStartOfYear,
  getEndOfYear,
} from "@/utils";
import {
  fetchRapportBillSupplier,
} from "@/api/purchase";

import {
  exportExcelRapportBillBySupplier,
} from "@/api/export";

import PageHeader from "@/components/PageHeader";
export default {
  name: "Rapport-Sales",

  components: { Pagination, PageHeader },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },

  data() {
    return {
      typeSearch: "week",
      exportLoading: false,
      showChart: false,
      activeName: "ALL",
      employees: [],
      options: [
        {
          label: "Journalier",
          value: "day",
        },
        {
          label: "Hebdomadaire",
          value: "week",
        },
        {
          label: "Mensuel",
          value: "month",
        },
        {
          label: "Annuel",
          value: "year",
        },
      ],
      chartCategories: [],
      chartSeries: [],

      resume: {
        total_invoice: 0,
        cost_purchase: 0,
        count_item: 0,
        profit: 0,
      },
      listLoading: false,
      items: [],

      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "name",
        start: getStartOfWeek(),
        end: getEndOfWeek(),

      },
    };
  },
  computed: {
    btnSeachValid() {
      if (this.listQuery.start !== null && this.listQuery.end !== null) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.INIT_DATA();
  },
  methods: {

    async GET_FETCH_RAPPORT() {
      this.listLoading = true;
      await fetchRapportBillSupplier(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;

          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;


          console.log(this.chartSeries);

          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },


    INIT_DATA() {
      if (this.listQuery.start !== null && this.listQuery.end !== null) {

        this.GET_FETCH_RAPPORT();
      }
    },

    HANDLE_PLAGE(e) {
      if (e === "day") {
        this.listQuery.start = getDay();
        this.listQuery.end = getDay();
      }
      if (e === "week") {
        this.listQuery.start = getStartOfWeek();
        this.listQuery.end = getEndOfWeek();
      }
      if (e === "month") {
        this.listQuery.start = getStartOfMonth();
        this.listQuery.end = getEndOfMonth();
      }
      if (e === "year") {
        this.listQuery.start = getStartOfYear();
        this.listQuery.end = getEndOfYear();
      }
      this.INIT_DATA();
    },

   /* exportRapportSalesPDF() {
      this.exportLoading = true;
      if (this.listQuery.saleagent == "" || this.listQuery.saleagent === null) {
        this.listQuery.saleagent = undefined;
      }
      exportRapportCustomerSale(this.listQuery)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "rapporte_vente_client_du" +
                this.listQuery.start +
                "_" +
                this.listQuery.end +
                ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.exportLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },*/
    exportRapportBillSupplierExcel() {
      this.exportLoading = true;
      exportExcelRapportBillBySupplier(this.listQuery)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            this.listQuery.end +
              "_" +
              this.listQuery.end +
              "rapport_achat_supplier.xls"
          ); //or any other extension
          document.body.appendChild(link);
          link.click();
          this.exportLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    HANDLE_TYPE_RAPPORT() {
      this.INIT_DATA();
    },
    HANDLE_EXPORT(command) {
      switch (command) {
        case "PDF":
          break;
        case "EXCEL":
          this.exportRapportBillSupplierExcel();
          break;
      }
    },
    handleShowChart() {
      this.showChart = !this.showChart;
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "INVOICE":
          this.listQuery.type = "INVOICE";
          break;
        case "TICKET_POS":
          this.listQuery.type = "TICKET_POS";
          break;

        default:
          this.listQuery.type = undefined;
      }
      this.INIT_DATA();
    },
  },
};
</script>

<style lang="scss" scoped></style>
